<template>
  <div class="partition-form van-common-detail-button">
    <van-form ref="appForm" :input-align="constants.inputAlign" label-width="110px">
      <div class="area-title">
        <p class="title">
          货物信息
        </p>
      </div>
      <van-cell title="提货申请编号：" :value="utils.isEffectiveCommon(appForm.applyNo)" />
      <van-cell title="仓库名称：" :value="utils.isEffectiveCommon(appForm.warehouseName)" />
      <van-cell title="提货类型：" :value="utils.statusFormat(_.toString(appForm.outType),'StorageOutType')" />
      <van-cell title="申请提货日期：" :value="utils.dataString(appForm.applyDate)" />
      <van-cell title="柜号：" :value="utils.isEffectiveCommon(appForm.cabinetNo)" />
      <van-cell title="货物名称：" :value="utils.isEffectiveCommon(appForm.cargoName)" />
      <van-cell title="提货方式：" :value="appForm.cargoType===1? '按件提货':'按重量提货'" />
      <van-cell-group v-for="(item,index) in _.get(appForm,'containerInfoVo.goodsList',[])" :key="item.id">
        <div class="van-common-left">
          <span>{{ index+1 }}</span>
        </div>
        <div class="van-common-right">
          <van-cell title="品名：" :value="utils.isEffectiveCommon(item.productName)" />
          <van-cell title="单价（原币）：" :value="utils.isEffectiveCommon(item.unitPrice)" />
          <van-cell title="认定单价（元）：" :value="utils.isEffectiveCommon(item.packageUnitPrice)" />
          <van-cell v-if="appForm.cargoType===1" title="可提件数：" :value="utils.isEffectiveCommon(item.extractableNumber)" />
          <van-cell v-else title="可提重量（KG）：" :value="utils.isEffectiveCommon(item.extractableWeight)" />
          <van-cell v-if="appForm.cargoType===1" title="实际件数：" :value="utils.isEffectiveCommon(item.actualPieceNumber)" />
          <van-cell v-else title="实际重量（KG）：" :value="utils.isEffectiveCommon(item.actualWeight)" />
          <van-cell :title="appForm.cargoType===1? '申请提货件数':'申请提货重量'" :value="utils.isEffectiveCommon(item.applyNumber)" />
        </div>
      </van-cell-group>
      <van-cell :title="appForm.cargoType===1? '提货总件数':'提货总重量'" :value="utils.isEffectiveCommon(totalNumber)" />
      <van-cell title="提货总金额（元）：" :value="utils.isEffectiveCommon(totalMoney)" />
      <div class="area-title">
        <p class="title">
          装车信息
        </p>
      </div>
      <van-cell title="司机姓名：" :value="utils.isEffectiveCommon(appForm.vehiclesInfoVo.contacts)" />
      <van-cell title="电话：" :value="utils.isEffectiveCommon(appForm.vehiclesInfoVo.phone)" />
      <van-cell title="身份证号：" :value="utils.isEffectiveCommon(appForm.vehiclesInfoVo.certificates)" />
      <van-cell title="车牌号：" :value="utils.isEffectiveCommon(appForm.vehiclesInfoVo.numberPlates)" />
      <van-cell title="备注：" :value="utils.isEffectiveCommon(appForm.vehiclesInfoVo.remarks)" />
    </van-form>
    <div class="button-fixed">
      <van-button type="info" block @click="$router.back()">
        返回
      </van-button>
    </div>
  </div>
</template>

<script>
import { List, PullRefresh, Cell, CellGroup, Search, Button, Row, Col, Form, Field, Popup, Picker, Uploader, SwipeCell, Toast, Calendar, Checkbox, CheckboxGroup, RadioGroup, Radio, DatetimePicker } from 'vant'

export default {
  components: {
    [DatetimePicker.name]: DatetimePicker,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [CheckboxGroup.name]: CheckboxGroup,
    [Checkbox.name]: Checkbox,
    [Calendar.name]: Calendar,
    [Toast.name]: Toast,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Search.name]: Search,
    [Button.name]: Button,
    [Row.name]: Row,
    [Col.name]: Col,
    [Form.name]: Form,
    [Field.name]: Field,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Uploader.name]: Uploader,
    [SwipeCell.name]: SwipeCell
  },
  data () {
    return {
      stockOutId: this.$route.query.stockOutId,
      showApplyDate: false,
      currentDate: new Date(),
      accountForm: {},
      showGoods: false,
      detailList: [],
      totalNumber: '',
      totalMoney: '',
      appForm: {
        applyNo: '',
        applyDate: '',
        cabinetNo: '',
        cargoName: '',
        money: '',
        extractableNumber: '',
        warehouseName: '',
        extractableWeight: '',
        actualPieceNumber: '',
        actualWeight: '',
        vehiclesInfoVo: {},
        containerInfoVo: {},
        goodsList: []
      },
      finishedText: '没有更多了',
      page: 1,
      tableList: [],
      loading: false,
      finished: false,
      refreshing: false,
      loadingSubmit: false
    }
  },
  created () {
    this.getDetail()
  },
  methods: {
    // 获取详情
    getDetail () {
      this.api.delivery.packOutDetail(this.stockOutId).then(result => {
        const resData = result.data.value
        this.appForm = this._.assign(this.appForm, resData)
        this.applyNumberBlur()
      }).catch((e) => {
      })
    },
    applyNumberBlur () {
      this.totalNumber = 0
      this.totalMoney = 0
      if (!this._.isEmpty(this._.get(this.appForm, 'containerInfoVo.goodsList', []))) {
        this.appForm.containerInfoVo.goodsList.forEach(item => {
          const date = this._.toNumber(item.applyNumber)
          if (this._.isNumber(date) && !this._.isNaN(date)) {
            this.totalNumber += date
            if (this.appForm.cargoType === 1) {
              this.totalMoney += date * this._.toNumber(item.packageUnitPrice)
            } else {
              this.totalMoney += date * this._.toNumber(item.unitPrice)
            }
          }
        })
      }
    }
  }
}
</script>

<style>
</style>
