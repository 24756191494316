var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "partition-form van-common-detail-button" },
    [
      _c(
        "van-form",
        {
          ref: "appForm",
          attrs: {
            "input-align": _vm.constants.inputAlign,
            "label-width": "110px"
          }
        },
        [
          _c("div", { staticClass: "area-title" }, [
            _c("p", { staticClass: "title" }, [_vm._v(" 货物信息 ")])
          ]),
          _c("van-cell", {
            attrs: {
              title: "提货申请编号：",
              value: _vm.utils.isEffectiveCommon(_vm.appForm.applyNo)
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "仓库名称：",
              value: _vm.utils.isEffectiveCommon(_vm.appForm.warehouseName)
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "提货类型：",
              value: _vm.utils.statusFormat(
                _vm._.toString(_vm.appForm.outType),
                "StorageOutType"
              )
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "申请提货日期：",
              value: _vm.utils.dataString(_vm.appForm.applyDate)
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "柜号：",
              value: _vm.utils.isEffectiveCommon(_vm.appForm.cabinetNo)
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "货物名称：",
              value: _vm.utils.isEffectiveCommon(_vm.appForm.cargoName)
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "提货方式：",
              value: _vm.appForm.cargoType === 1 ? "按件提货" : "按重量提货"
            }
          }),
          _vm._l(
            _vm._.get(_vm.appForm, "containerInfoVo.goodsList", []),
            function(item, index) {
              return _c("van-cell-group", { key: item.id }, [
                _c("div", { staticClass: "van-common-left" }, [
                  _c("span", [_vm._v(_vm._s(index + 1))])
                ]),
                _c(
                  "div",
                  { staticClass: "van-common-right" },
                  [
                    _c("van-cell", {
                      attrs: {
                        title: "品名：",
                        value: _vm.utils.isEffectiveCommon(item.productName)
                      }
                    }),
                    _c("van-cell", {
                      attrs: {
                        title: "单价（原币）：",
                        value: _vm.utils.isEffectiveCommon(item.unitPrice)
                      }
                    }),
                    _c("van-cell", {
                      attrs: {
                        title: "认定单价（元）：",
                        value: _vm.utils.isEffectiveCommon(
                          item.packageUnitPrice
                        )
                      }
                    }),
                    _vm.appForm.cargoType === 1
                      ? _c("van-cell", {
                          attrs: {
                            title: "可提件数：",
                            value: _vm.utils.isEffectiveCommon(
                              item.extractableNumber
                            )
                          }
                        })
                      : _c("van-cell", {
                          attrs: {
                            title: "可提重量（KG）：",
                            value: _vm.utils.isEffectiveCommon(
                              item.extractableWeight
                            )
                          }
                        }),
                    _vm.appForm.cargoType === 1
                      ? _c("van-cell", {
                          attrs: {
                            title: "实际件数：",
                            value: _vm.utils.isEffectiveCommon(
                              item.actualPieceNumber
                            )
                          }
                        })
                      : _c("van-cell", {
                          attrs: {
                            title: "实际重量（KG）：",
                            value: _vm.utils.isEffectiveCommon(
                              item.actualWeight
                            )
                          }
                        }),
                    _c("van-cell", {
                      attrs: {
                        title:
                          _vm.appForm.cargoType === 1
                            ? "申请提货件数"
                            : "申请提货重量",
                        value: _vm.utils.isEffectiveCommon(item.applyNumber)
                      }
                    })
                  ],
                  1
                )
              ])
            }
          ),
          _c("van-cell", {
            attrs: {
              title: _vm.appForm.cargoType === 1 ? "提货总件数" : "提货总重量",
              value: _vm.utils.isEffectiveCommon(_vm.totalNumber)
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "提货总金额（元）：",
              value: _vm.utils.isEffectiveCommon(_vm.totalMoney)
            }
          }),
          _c("div", { staticClass: "area-title" }, [
            _c("p", { staticClass: "title" }, [_vm._v(" 装车信息 ")])
          ]),
          _c("van-cell", {
            attrs: {
              title: "司机姓名：",
              value: _vm.utils.isEffectiveCommon(
                _vm.appForm.vehiclesInfoVo.contacts
              )
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "电话：",
              value: _vm.utils.isEffectiveCommon(
                _vm.appForm.vehiclesInfoVo.phone
              )
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "身份证号：",
              value: _vm.utils.isEffectiveCommon(
                _vm.appForm.vehiclesInfoVo.certificates
              )
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "车牌号：",
              value: _vm.utils.isEffectiveCommon(
                _vm.appForm.vehiclesInfoVo.numberPlates
              )
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "备注：",
              value: _vm.utils.isEffectiveCommon(
                _vm.appForm.vehiclesInfoVo.remarks
              )
            }
          })
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "button-fixed" },
        [
          _c(
            "van-button",
            {
              attrs: { type: "info", block: "" },
              on: {
                click: function($event) {
                  return _vm.$router.back()
                }
              }
            },
            [_vm._v(" 返回 ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }